/** @jsx jsx */

import { graphql } from 'gatsby'
import { jsx, Text, Link, Button } from "theme-ui"

import Root from "@layouts/root"
import SEO from "@components/seo"
// import PostList from "@components/post-list"

const SiteIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  return (
    <Root location={location} title={siteTitle}>
      <SEO title="Ryen Beatty" />
      <Text sx={{ pb: 2 }}><b>Ryen Beatty</b> is a generalist frontend engineer and product designer.</Text>
    
      <Link href="mailto:mail@ryenbeatty.info">Email</Link>
      {` `}
      <Link href="//are.na/ryenbeatty">Linkedin</Link>
      {` `}
      <Link href="//are.na/ryenbeatty">Are.na</Link>
      {/* <Link href="//are.na/ryenbeatty">github</Link> */}
      {/* <Text sx={{ pb: 2 }}>Recent journal entries</Text>
      <PostList posts={posts} /> */}
    </Root>
  )
}

export default SiteIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 5
      filter: { fields: { collection: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD.MM.YY")
          }
        }
      }
    }
  }
`
